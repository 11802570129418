import {useState, useReducer, useEffect} from "react";
import PagerComponent from "./pager.component";
import SectionComponent from "./section.component";
import {
    inject_update_evaluation,
    injecte_create_ou_update_evaluation
} from "../../../../../../Services/Injecteurs/injecteur.service";
import {setEvaluationState} from "../../../../../../Services/storage.service";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

function useValidateEvaluation({demande_validation, busy, questionnaire_state, dispatch}) {

    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if (demande_validation){
            console.log('demande de valdation');
            dispatch({type:"set-busy", payload: true});
            if(!busy){
                console.log('pas busy on envoi');
                inject_update_evaluation(questionnaire_state)
                    .then( ( response ) => {
                        console.log('hm');

                        if (Object.keys(response).length > 0) {
                            toast.success(t("Toasts.remerciement"));
                            navigate('/');
                        } else {
                            console.log(response);
                        }
                    })
                    .catch( () => console.log('failed'))
                    .finally( () => {dispatch({type: "fin-de-validation", payload: true})});
            }
        }
    }, [demande_validation, busy]);
}

function reducer(state, action) {
    let new_state = {...state};
    let current_section = state['current-section'];
    let next_section_number, section, is_section_valide;

    switch(action.type)
    {
        case "change-valeur":
            const { question_id, valeur} = action.payload;
            new_state[`section-${current_section}`]['valide'] = true;
            let old_question_state = state[`section-${current_section}`][`question-${question_id}`] ?? { valeur: ''};
            new_state[`section-${current_section}`][`question-${question_id}`] = { ...old_question_state, valeur: valeur, valide: true };
            return new_state;
        case "section-suivante":
            // calcul la validité de la section
            section = state[`section-${current_section}`];
            is_section_valide = true;
            for ( let prop_ in section ){
                if ( prop_ !== 'valide' ) {
                    let question = section[prop_];
                    let is_question_valide = !question.requis || (question.requis && question.valeur !== "");
                    new_state[`section-${current_section}`][prop_]['valide'] = is_question_valide;
                    is_section_valide = is_section_valide && is_question_valide
                }
            }
            section['valide'] = is_section_valide;

            next_section_number = is_section_valide ? state['current-section'] + 1 : state['current-section'];
            next_section_number = next_section_number >= state['n-sections'] ? next_section_number-1 : next_section_number;

            new_state[`section-${current_section}`] = section;
            new_state['current-section'] = next_section_number;

            // new_state['busy'] = true;

            // on sauvegarde localement l'état :
            setEvaluationState(state['instance_type_evaluable_id'], new_state);

            // if ( is_section_valide ){
            //     // si dernière section, on upload le formulaire
            //     if ( new_state['current-section'] >= new_state['n-sections'] ){
            //         injecte_create_ou_update_evaluation(new_state)
            //             .then( ( response ) => {console.log(response)})
            //             .catch( () => console.log('failed'))
            //             .finally( () => {action.dispatch({type: "set-busy", payload: false})});
            //     }
            // }

            return new_state;
        case "section-precedente":
            next_section_number = state['current-section'] - 1;
            next_section_number = next_section_number <= -1 ? 0 : next_section_number;
            new_state['current-section'] = next_section_number;
            return new_state;

        case "set-busy":
            new_state['busy'] = action.payload;
            return new_state;

        case "fin-de-validation":
            new_state['busy'] = false;
            new_state['demande_validation'] = false;
            return new_state;
        case "valider":

            // dernière section :
            section = state[`section-${current_section}`];
            is_section_valide = true;
            for ( let prop_ in section ){
                if ( prop_ !== 'valide' ) {
                    let question = section[prop_];
                    let is_question_valide = !question.requis || (question.requis && question.valeur !== "");
                    new_state[`section-${current_section}`][prop_]['valide'] = is_question_valide;
                    is_section_valide = is_section_valide && is_question_valide
                }
            }
            section['valide'] = is_section_valide;

            new_state[`section-${current_section}`] = section;

            if ( is_section_valide && ! new_state['busy'] ) {
                // voir s'il est possible de passer par un cusomt hook
                // en utilisant une variable
                new_state['demande_validation'] = true;
                // injecte_create_ou_update_evaluation(new_state)
                //     .then( ( response ) => {console.log(response)})
                //     .catch( () => console.log('failed'))
                //     .finally( () => {action.dispatch({type: "set-busy", payload: false})});            }
            }
            return new_state;
        case "navigate_post_validation":
            new_state['navigate_post_validation'] = true;
            return new_state;
        default:
            return {...state};
    }
}

const DetailQuestionnaireComponent = ({questionnaire, evaluation_id}) => {

    // on initialise l'état avec les réponses du serveur
    const initialiseReducer = (init_state) => {

        // pour toutes les sections, on définit les questions et leur valeur par défaut et propriétés
        let state = {...init_state};
        let section_parsed = 0;
        for ( let section of questionnaire.sections ){
            state[`section-${section_parsed}`] = { valide: true };
            for ( let question of section.questions ){
                // si réponse à la question on définit, sinon on set en fonction du type de question
                let valeur_par_defaut = (question.reponses && question.reponses.length && question.reponses[0]) ? question.reponses[0].valeur : "";

                switch( question.representation.component ){
                    case 'QuestionRange':
                        valeur_par_defaut = valeur_par_defaut.length ? valeur_par_defaut : question.representation.min;
                        break;
                    case 'QuestionTexte' :
                    default:
                        break;
                }
                state[`section-${section_parsed}`][`question-${question.id}`] = {
                    valeur: valeur_par_defaut,
                    requis: question.requis,
                    valide: true,
                }
            }
            section_parsed += 1;
        }
        state['current-section'] = 0;
        state['n-sections'] = questionnaire.sections.length;
        state['questionnaire_id'] = questionnaire.id;
        state['instance_type_evaluable_id'] = questionnaire.type_evaluable.id;
        state['busy'] = false;
        state['demande_validation'] = false;
        state['navigate_post_validation'] = false;
        state['evaluation_id'] = evaluation_id;

        return state;
    }
    const [ questionnaireState, dispatch ] = useReducer(reducer,{},initialiseReducer);

    useValidateEvaluation(
        {
            demande_validation: questionnaireState.demande_validation,
            busy: questionnaireState.busy,
            questionnaire_state: questionnaireState,
            dispatch: dispatch});



    // voir pour transformer la délégation par un fournisseur

    let section = ( questionnaire.sections && questionnaire.sections.length > 0 ) ?
        questionnaire.sections[questionnaireState['current-section']] : undefined;


    return (
      <>
          <PagerComponent sectionsCount={questionnaire.sections ? questionnaire.sections.length : 0}
                          dispatch={dispatch} questionnaireState={questionnaireState}
          >
              {section ? (<SectionComponent section={section}
                              dispatch={dispatch}
                              questionnaireState={questionnaireState}/>) : (<></>)}

          </PagerComponent>
      </>
    );

};

export default DetailQuestionnaireComponent;