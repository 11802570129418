/**
 * ./src/Services/Chargeurs/chargeur.evaluation.js
 *
 * Chargeur d'évaluation
 * Contient le chargeur d'évaluations effectuées par les anonymes et
 * le chargeur d'évaluations effectuées
 */



import {db, update_evaluation} from "../database.service";
import {data_getEvaluation} from "../data.service";
import {
    getAnonymousRefreshToken,
    getAnonymousUserToken,
    getIdentifiedRefreshToken,
    getIdentifiedUserToken, setAnonymousRefreshToken, setAnonymousUserToken,
    setIdentifiedRefreshToken, setIdentifiedUserToken,
    getEvenementId
} from "../storage.service";
import {
    net_fetch_evaluation,
    net_refresh_token,
    net_fetch_evaluation_filtered_by_competiteur_name,
    USER_TYPE_IDENTIFIED
} from "../network.action.service";


// comme chaque évaluation est liée à un questionnaire et un utilisateur
// on a techniquement pas besoin de spécifier l'évènement
// juste le numéro de l'évaluation.

const charge_evaluation_pour_utilisateur_identifie = async (evaluation_id) => {
    let evaluation_response;
    let user_token = getIdentifiedUserToken();
    if (navigator.onLine){
        evaluation_response = await net_fetch_evaluation(evaluation_id, user_token);
        if ( 401 === evaluation_response.status )
        {
            let refresh_token = getIdentifiedRefreshToken();
            let refresh_response = await net_refresh_token(refresh_token);

            if ( refresh_response.ok ){
                setIdentifiedRefreshToken(refresh_response.data.refresh_token);
                setIdentifiedUserToken(refresh_response.data.token);
            }else{
                throw new Error('login');
            }
            user_token = getIdentifiedUserToken();
            evaluation_response = await net_fetch_evaluation(evaluation_id, user_token);
        }
        if ( ! evaluation_response.ok ){
            return {};
        }
        if ( db ){
            return new Promise( (resolve, reject) => {
                update_evaluation(db, evaluation_id, evaluation_response.data)
                    .catch( err => console.log(err))
                    .finally( () => {
                        let data_to_resolve = evaluation_response.data;
                        resolve(data_to_resolve);
                    })
            })
        }else{
            return evaluation_response.data;
        }
    }else{
        // navigator offline
        // on tente via la base de données
        if ( db ){
            return new Promise( (resolve, reject) => {
                db.evaluation.where({evaluation_id: evaluation_id}).first()
                    .then( (evaluation_item) => {
                        let data_to_resolve = evaluation_item.evaluation;
                        resolve(data_to_resolve);
                    })
                    .catch( err => console.log(err));
            })
        }
        // si pas de base de données, on attaque le cache fetch si jamais il existe
        evaluation_response = await net_fetch_evaluation(evaluation_id, user_token);
        if ( ! evaluation_response.ok ){
            return {};
        }

        // si réponse dans le cache fetch, on la renvoie
        return evaluation_response.data;
    }
}

const charge_evaluation_pour_utilisateur_anonyme = async (evaluation_id) => {
    let evaluation_response;
    let user_token = getAnonymousUserToken();
    if (navigator.onLine){
        evaluation_response = await net_fetch_evaluation(evaluation_id, user_token);
        if ( 401 === evaluation_response.status )
        {
            let refresh_token = getAnonymousRefreshToken();
            let refresh_response = await net_refresh_token(refresh_token);

            if ( refresh_response.ok ){
                setAnonymousRefreshToken(refresh_response.data.refresh_token);
                setAnonymousUserToken(refresh_response.data.token);
            }else{
                throw new Error('login');
            }
            user_token = getAnonymousUserToken();
            evaluation_response = await net_fetch_evaluation(evaluation_id, user_token);
        }
        if ( ! evaluation_response.ok ){
            return {};
        }
        if ( db ){
            return new Promise( (resolve, reject) => {
                update_evaluation(db, evaluation_id, evaluation_response.data)
                    .catch( err => console.log(err))
                    .finally( () => {
                        let data_to_resolve = evaluation_response.data;
                        resolve(data_to_resolve);
                    })
            })
        }else{
            return evaluation_response.data;
        }
    }else{
        // navigator offline
        // on tente via la base de données
        if ( db ){
            return new Promise( (resolve, reject) => {
                db.evaluation.where({evaluation_id: evaluation_id}).first()
                    .then( (evaluation_item) => {
                        let data_to_resolve = evaluation_item.evaluation;
                        resolve(data_to_resolve);
                    })
                    .catch( err => console.log(err));
            })
        }
        // si pas de base de données, on attaque le cache fetch si jamais il existe
        evaluation_response = await net_fetch_evaluation(evaluation_id, user_token);
        if ( ! evaluation_response.ok ){
            return {};
        }

        // si réponse dans le cache fetch, on la renvoie
        return evaluation_response.data;
    }
}

const charge_evaluation = async (evaluation_id, user_type) => {
    let evaluation_response;
    if ( USER_TYPE_IDENTIFIED === user_type ) {
        evaluation_response = await charge_evaluation_pour_utilisateur_identifie(evaluation_id);
    }else{
        evaluation_response = await charge_evaluation_pour_utilisateur_anonyme(evaluation_id);
    }
    return evaluation_response;
}

const charge_evaluation_pour_competiteur_name = async (competiteur_name) => {
    let evaluation_response;
    let user_token = getIdentifiedUserToken();
    if (navigator.onLine) {
        evaluation_response = await net_fetch_evaluation_filtered_by_competiteur_name(user_token, competiteur_name);
        if ( 401 === evaluation_response.status )
        {
            let refresh_token = getIdentifiedRefreshToken();
            let refresh_response = await net_refresh_token(refresh_token);

            if ( refresh_response.ok ){
                setIdentifiedRefreshToken(refresh_response.data.refresh_token);
                setIdentifiedUserToken(refresh_response.data.token);
            }else{
                throw new Error('login');
            }
            user_token = getIdentifiedUserToken();
            evaluation_response = await net_fetch_evaluation_filtered_by_competiteur_name(user_token, competiteur_name);
        }
        if ( evaluation_response.ok ){
            return evaluation_response.data;
        }else{
            return [];
        }
    }
    return [];
}

export { charge_evaluation, charge_evaluation_pour_competiteur_name };