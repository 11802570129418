import {Link} from "react-router-dom";
import { useNavigate,toggleHandler } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MenuItem = ({path,icon,title,toggleHandler}) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const handleClick = () => {
		toggleHandler();
		navigate(path);
	}

	return ( <div className="navigation-menu__item" onClick={handleClick}>
		<div className="icon-wrapper" >
			{icon}
		</div>
		<div className="text-wrapper">
			{t(title)}
		</div>
	</div>);
}

export { MenuItem }
