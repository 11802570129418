import {useEffect, useState} from "react";
import {NetworkAccessDecorator} from "../../../Services/NetworkAccessDecorator";
import {charge_questionnaire} from "../../../Services/Chargeurs/chargeur.questionnaire";
import {charge_evaluation_pour_competiteur_name} from "../../../Services/Chargeurs/chargeur.evaluation";
import {inject_update_evaluation_verrou} from "../../../Services/Injecteurs/injecteur.service";
import {useTranslation} from "react-i18next";

const EvaluationItem = ({evaluation}) => {
    const [estAccessible, setEstAccessible] = useState( ! evaluation?.verrou);
    const [changingVerrou, setChangingVerrou] = useState( false );

    useEffect( () => {
       if (changingVerrou){
           // update Evaluation verrou
           const changeVerrouValue = async () => {
               //fait des trucs
               let response = await inject_update_evaluation_verrou(evaluation?.id, ! estAccessible);
               setChangingVerrou(false);
           };

           changeVerrouValue();
       }
    }, [changingVerrou]);
    const handleChange = () => {
        setEstAccessible( !estAccessible );
        setChangingVerrou( true );
    }
    return (
        <>
            <div className={`col-span-3 my-1`}>
                <div className={`flex flex-col items-start justify-start gap-1 font-bold`}>
                    <div>{evaluation?.competiteur?.instance_name}</div>
                    <div className={`text-xs`}>{evaluation?.competiteur?.real_instance_name}</div>
                </div>
            </div>
            <div className={`col-span-3 my-1 place-self-center w-full text-sm`}>
                <div className={`flex flex-col items-start justify-start gap-1`}>
                    <div>{evaluation?.repondant?.nom}</div>
                    <div>{evaluation?.repondant?.prenom}</div>
                </div>
            </div>
            <div className={`col-span-1 my-1 place-self-center`}>
                {
                    changingVerrou ?
                        (<div className={`loading loading-spinner loading-xs`}></div>) :
                        (<input type={`checkbox`} className={`toggle`}
                                defaultChecked={estAccessible}
                                value={estAccessible}
                                onChange={handleChange}/>)
                }
            </div>
        </>
    );
}
const ListeEvaluation = ({evaluations, searching}) => {

    const {t} = useTranslation();

    const loading = () => {
        return (
            <div className={`loading loading-spinner loading-sm col-span-7 place-self-center my-4`}></div>
        );
    }
    const display = () => {
        return (<>
            <div className={`col-span-3 font-bold text-xl mt-4 `}>
                {t('Components.EvaluationsFiltrees.nom')}
            </div>
            <div className={`col-span-3 place-self-center text-xl w-full font-bold mt-4 `}>
                {t('Components.EvaluationsFiltrees.degustateur')}
            </div>
            <div className={`col-span-1 place-self-center text-xl font-bold mt-4 `}>
                {t('Components.EvaluationsFiltrees.activation')}
            </div>

            { evaluations?.map((evaluation, index) => <EvaluationItem evaluation={evaluation} key={index}/>) }

            </>
        );
    }
    return (
        <div className={`grid grid-cols-7 gap-2 w-full`}>
            {searching ? loading() : display()}
        </div>
    )
}

const RechercheEvaluation = () => {

    const [competiteurName, setCompetiteurName] = useState("");
    const [searchCompetiteur, setSearchCompetiteur] = useState(false);
    const [evaluationList, setEvaluationList] = useState([]);

    useEffect(() => {
        if (searchCompetiteur) {
            const process = async () => {
                let data = await NetworkAccessDecorator(charge_evaluation_pour_competiteur_name, competiteurName);
                setEvaluationList(data);
                setSearchCompetiteur(false);
            }
            process();
        }
    }, [searchCompetiteur]);

    const submitSearch = (event) => {
        setSearchCompetiteur(true);
        event.preventDefault();
        event.stopPropagation();
    }

    return (
        <div
            className={`flex flex-col items-start justify-start w-full col-span-1 sm:col-span-2 lg:col-span-3 2xl:col-span-4`}>
            <form className={`flex flex-row items-center justify-start w-full gap-4`} onSubmit={submitSearch}>
                <input type="text" id={`competiteur_name`}
                       name={`competiteur_name`}
                       onChange={(e) => setCompetiteurName(e.target.value)}
                       value={competiteurName}
                       placeholder={`Nom du compétiteur`}
                       className={`px-4 py-2 rounded-2xl text-black`}
                />
                <button type="submit"
                        className={`bg-primary-content text-primary border-primary-content text-base font-medium hover:bg-accent hover:border-accent hover:text-neutral-content btn btn-sm`}>
                    <svg xmlns="http://www.w3.org/2000/svg"
                         className={`fill-primary w-4 h-4`}
                         viewBox="0 0 512 512">
                        <path
                            d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/>
                    </svg>
                </button>
            </form>
            <ListeEvaluation evaluations={evaluationList} searching={searchCompetiteur}/>
        </div>
    )
}

export {RechercheEvaluation};