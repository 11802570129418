import {getIdentifiedUserToken} from "../../../../Services/storage.service";
import {getUserName, isUserOrganisateur} from "../../../../Services/generic.helpers";
import {useTranslation} from "react-i18next";
import {Await, useAsyncValue, useLoaderData, useNavigate} from "react-router-dom";
import {Suspense, useEffect, useState} from "react";
import LoadingComponent from "../../../../Components/Elements/loading.component";

import ListeQuestionnaire from "../../Components/ListeQuestionnaire";
import ListeEvenementAgendaAujourdhui from "../../Components/ListeEvenementAgendaAujourdhui";
import {ListeEvenementAVenir} from "../../Components/ListeEvenementAVenir";
import {IconCalendar} from "../../Assets/Icons/IconCalendar";
import {RechercheEvaluation} from "../../Components/RechercheEvaluation";


const EspaceMembre = () => {

    const user_token = getIdentifiedUserToken();
    const user_name = getUserName(user_token);
    const is_organisateur = isUserOrganisateur(user_token);

    const { t } = useTranslation();
    const { data } = useLoaderData();

    const oneLineClass = "col-span-1 sm:col-span-2 lg:col-span-3 2xl:col-span-4";

    const date_today = new Date();
    const today = `${date_today.getFullYear()}${(''+(date_today.getMonth()+1)).padStart(2, "0")}${(''+date_today.getDate()).padStart(2, "0")}`;

    return (
        <Suspense fallback={<LoadingComponent/>}>
            <Await resolve={data}>
                {/* mettre un gestionnaire pour la redirection */}
                <div
                    className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 max-w-[2048px] mx-auto my-12 px-6 w-full">
                    <h2 className={`${oneLineClass} flex flex-col`}>
                        <span className={`text-lg font-normal`}>{t('Page.EspaceMembre.bienvenue')}</span>
                        <span className={`text-2xl text-primary-content font-extrabold`}>{user_name}</span>
                    </h2>
                    <div className={`${oneLineClass} separateur w-full h-8 mb-2 border-b-2 border-accent`}></div>
                    <h2 className={`${oneLineClass} text-xl font-extrabold`}>{t('Components.ListeDesQuestionnaires.titre')}</h2>
                    <ListeQuestionnaire questionnaires={data.questionnaires}/>
                    <div className={`${oneLineClass} separateur w-full h-8 mb-2 border-b-2 border-accent`}></div>
                    <h2 className={`${oneLineClass} text-xl font-extrabold`}>{t('Components.ListeQuotidienneEvenementAgendas.aujourdhui')}</h2>
                    {/*<ListeEvenementAgendaAujourdhui evenement_agendas={data.evenements_agenda}/>*/}
                    {/*<div className={`${oneLineClass} separateur w-full h-8 mb-2 border-b-2 border-accent`}></div>*/}
                    {/*<h2 className={`${oneLineClass} text-xl font-extrabold flex gap-2`}>*/}
                    {/*    <span className={`flex gap-4 items-center`}>*/}
                    {/*    <span>{t('Components.ListeAvenirEvenementAgendas.avenir')}</span>*/}
                    {/*    <a href={`/agenda/${today}`} className={`flex`}>*/}
                    {/*    <IconCalendar className={`w-4 fill-primary-content`}/>*/}
                    {/*    </a>*/}
                    {/*    </span>*/}
                    {/*</h2>*/}
                    <ListeEvenementAVenir evenement_agendas={data.evenements_agenda_tomorrow}/>
                    {
                        is_organisateur ? (
                            <>
                                <div
                                    className={`${oneLineClass} separateur w-full h-8 mb-2 border-b-2 border-accent`}></div>
                            <h2 className={`${oneLineClass} text-xl font-extrabold`}>{t('Components.Recherche.titre')}</h2>
                        <RechercheEvaluation/>
                            </>
                        ) : (<></>)
                    }
                </div>
            </Await>
        </Suspense>
    );
};

export {EspaceMembre}