import jwt_decode from "jwt-decode";

const wrapPromise = promise => {
    let status = "pending";
    let result = "";
    let suspender = promise.then(
        res => {
            status = "success";
            result = res;
        },
        err => {
            status = "error";
            result = err;
        }
    );
    return {
        read() {
            if (status === "pending") {
                console.log("pending => send suspended promise")
                throw suspender;
            } else if (status === "error") {
                console.log("error => send error result")
                throw result;
            } else if (status === "success" ){
                console.log("success => send result");
                return result;
            } else {
                console.log("no case \_-(^^)_/ ")
                throw suspender;
            }
            // console.log("no more pending, sending result.");
            // return result;
        }
    };
};

const getUserName = (user_token) => {
    if ( user_token ){
        const o_user_token = user_token ? jwt_decode(user_token) : null;
        return o_user_token?.nom ?? '';
    }
    return '';
}

const isUserOrganisateur = (user_token) => {
    if ( user_token ){
        const o_user_token = user_token ? jwt_decode(user_token) : null;
        return o_user_token?.organisateur ?? false;
    }
    return false;
}

// prend une chaine avec le tz et sort une date reconstruite pour le client
const transformDate = (stringDateWithTz) => {
    if (stringDateWithTz){
        let f = stringDateWithTz.match(/(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2})/);
        let year = f[1];
        let month = f[2];
        month = parseInt(month)-1;
        let day = f[3];
        let hour = f[4];
        let minute = f[5];

        let date = new Date();
        date.setDate(1);
        date.setFullYear(year);
        date.setMonth(month);
        date.setDate(day);
        date.setHours(hour);
        date.setMinutes(minute);

        // let date = new Date(Date.parse(`${year}-${month}-${day}T${hour}:${minute}`));

        return date;
    }
}

export { wrapPromise, getUserName, transformDate, isUserOrganisateur };