import {
    addSynchronizationTask,
    getAnonymousRefreshToken,
    getAnonymousUserToken,
    getEvenementId,
    getIdentifiedRefreshToken, getIdentifiedUserToken, setAnonymousRefreshToken, setAnonymousUserToken,
    setIdentifiedRefreshToken, setIdentifiedUserToken
} from "../storage.service";
import {db, update_questionnaire_anonyme} from "../database.service";
import {
    net_create_evaluation_anonyme,
    net_refresh_token,
    net_update_evaluation, net_update_evaluation_verrou,
    net_update_or_create_evaluation
} from "../network.action.service";
import {create} from "axios";

// on a pas besoin de vérifier si l'utilisateur est connecté
const injecte_create_ou_update_evaluation = async (questionnaire_state) => {

    let network_payload = {
        questionnaire: {
            id: questionnaire_state.questionnaire_id,
            instance_type_evaluable_id: questionnaire_state.instance_type_evaluable_id,
        }
    };

    const section_number = questionnaire_state['n-sections'];
    network_payload['questionnaire']['sections'] = []
    let n_questions, matches;
    let question_regex = new RegExp("question-(\\d+)");
    for ( let i=0; i<section_number; i++)
    {
        network_payload['questionnaire']['sections'].push({
            questions:[]
        });
        // console.log(questionnaire_state);
        for( const _prop in questionnaire_state[`section-${i}`] ){
            // console.log(_prop);
            // console.log(questionnaire_state[`section-${i}`][_prop]);
            matches = _prop.match(question_regex);
            if ( matches ){
                network_payload['questionnaire']['sections'][i]['questions'].push({
                    id: matches[1],
                    valeur: questionnaire_state[`section-${i}`][_prop]['valeur']
                });
            }
        }
    }

    let evenement_id = getEvenementId();
    let anonymous_token = getAnonymousUserToken();
    let evaluation_response, restaurant_id;


    if ( questionnaire_state.evaluation_id ){
        // on update l'évaluation.

        console.log('on update ?');
        network_payload['evaluation_id'] = questionnaire_state.evaluation_id;

        if ( navigator.onLine ){
            evaluation_response = await net_update_evaluation(questionnaire_state.evaluation_id, network_payload, anonymous_token);
            if ( 401 === evaluation_response.status ){
                let refresh_token = getAnonymousRefreshToken();
                let refresh_response = await net_refresh_token(refresh_token);

                if ( refresh_response.ok ){
                    setAnonymousRefreshToken(refresh_response.data.refresh_token);
                    setAnonymousUserToken(refresh_response.data.token);
                }else{
                    // si depuis un injecteur, on ne peut pas refresh un utilisateur anonyme
                    // c'est trop étrange

                    // voir pour faire un fail safe :
                    // si pas de refresh, on utilise un unique id généré par le client
                    // et on essaye d'identifier le client par id

                    return {};
                }

                anonymous_token = getAnonymousUserToken();
                evaluation_response = await net_update_evaluation(questionnaire_state.evaluation_id, network_payload, anonymous_token);
            }
            if ( ! evaluation_response.ok ){
                // problème réseau, quoi renvoyer ?
                return {};
            }

            return evaluation_response.data;
            // restaurant_id = evaluation_response.data.restaurant.id;
            // if ( db ){
            //     return new Promise( (resolve, reject) => {
            //         update_questionnaire_anonyme(db,restaurant_id,evaluation_response.data)
            //             .catch( err => console.log(err) )
            //             .finally( () => {
            //                 let data_to_resolve = evaluation_response.data;
            //                 resolve(data_to_resolve)});
            //     });
            // }else{
            //     return evaluation_response.data;
            // }
        }

        addSynchronizationTask(injecte_create_ou_update_evaluation, questionnaire_state);

        return {};
    }

    // on crée une nouvelle évaluation

    // payload factory ?



    if ( navigator.onLine ){
        evaluation_response = await net_create_evaluation_anonyme(network_payload, anonymous_token);
        if ( 401 === evaluation_response.status ){
            let refresh_token = getAnonymousRefreshToken();
            let refresh_response = await net_refresh_token(refresh_token);

            if ( refresh_response.ok ){
                setAnonymousRefreshToken(refresh_response.data.refresh_token);
                setAnonymousUserToken(refresh_response.data.token);
            }else{
                // si depuis un injecteur, on ne peut pas refresh un utilisateur anonyme
                // c'est trop étrange

                // voir pour faire un fail safe :
                // si pas de refresh, on utilise un unique id généré par le client
                // et on essaye d'identifier le client par id

                return {};
            }

            anonymous_token = getAnonymousUserToken();
            evaluation_response = await net_create_evaluation_anonyme(network_payload, anonymous_token);
        }
        if ( ! evaluation_response.ok ){
            // problème réseau, quoi renvoyer ?
            return {};
        }

        return evaluation_response.data;
        // restaurant_id = evaluation_response.data.restaurant.id;
        // if ( db ){
        //     return new Promise( (resolve, reject) => {
        //         update_questionnaire_anonyme(db,restaurant_id,evaluation_response.data)
        //             .catch( err => console.log(err) )
        //             .finally( () => {
        //                 let data_to_resolve = evaluation_response.data;
        //                 resolve(data_to_resolve)});
        //     });
        // }else{
        //     return evaluation_response.data;
        // }
    }

    // si le navigateur n'est pas en ligne, on ajoute l'action de synchronisation à la file
    // de synchronisation
    addSynchronizationTask(injecte_create_ou_update_evaluation, questionnaire_state);

    // si le navigateur n'est pas en ligne, on attend qu'il le soit
    // le réducteur devra utiliser localstorage pour spécifier ce qu'il reste
    // à mettre à jour

    return {};

}

const inject_update_evaluation = async (questionnaire_state) => {

    let network_payload = {
        questionnaire: {
            id: questionnaire_state.questionnaire_id,
            instance_type_evaluable_id: questionnaire_state.instance_type_evaluable_id,
        }
    };

    const section_number = questionnaire_state['n-sections'];
    network_payload['questionnaire']['sections'] = []
    let n_questions, matches;
    let question_regex = new RegExp("question-(\\d+)");
    for ( let i=0; i<section_number; i++)
    {
        network_payload['questionnaire']['sections'].push({
            questions:[]
        });
        // console.log(questionnaire_state);
        for( const _prop in questionnaire_state[`section-${i}`] ){
            // console.log(_prop);
            // console.log(questionnaire_state[`section-${i}`][_prop]);
            matches = _prop.match(question_regex);
            if ( matches ){
                network_payload['questionnaire']['sections'][i]['questions'].push({
                    id: matches[1],
                    valeur: questionnaire_state[`section-${i}`][_prop]['valeur']
                });
            }
        }
    }

    let evenement_id = getEvenementId();
    let user_token = getIdentifiedUserToken();
    let evaluation_response, restaurant_id;


    if ( questionnaire_state.evaluation_id ){
        // on update l'évaluation.

        network_payload['evaluation_id'] = questionnaire_state.evaluation_id;

        if ( navigator.onLine ){
            evaluation_response = await net_update_evaluation(questionnaire_state.evaluation_id, network_payload, user_token);
            if ( 401 === evaluation_response.status ){
                let refresh_token = getIdentifiedUserToken();
                let refresh_response = await net_refresh_token(refresh_token);

                if ( refresh_response.ok ){
                    setIdentifiedRefreshToken(refresh_response.data.refresh_token);
                    setIdentifiedUserToken(refresh_response.data.token);
                }else{
                    // si depuis un injecteur, on ne peut pas refresh un utilisateur anonyme
                    // c'est trop étrange

                    // voir pour faire un fail safe :
                    // si pas de refresh, on utilise un unique id généré par le client
                    // et on essaye d'identifier le client par id

                    return {};
                }

                user_token = getIdentifiedUserToken();
                evaluation_response = await net_update_evaluation(questionnaire_state.evaluation_id, network_payload, user_token);
            }
            if ( ! evaluation_response.ok ){
                // problème réseau, quoi renvoyer ?
                return {};
            }

            return evaluation_response.data;
            // restaurant_id = evaluation_response.data.restaurant.id;
            // if ( db ){
            //     return new Promise( (resolve, reject) => {
            //         update_questionnaire_anonyme(db,restaurant_id,evaluation_response.data)
            //             .catch( err => console.log(err) )
            //             .finally( () => {
            //                 let data_to_resolve = evaluation_response.data;
            //                 resolve(data_to_resolve)});
            //     });
            // }else{
            //     return evaluation_response.data;
            // }
        }

        addSynchronizationTask(inject_update_evaluation, questionnaire_state);

        return {};
    }

    return {};

}


const inject_create_ou_update_evaluation_2_pour_anonyme = async (questionnaire_dto) => {
    const networkPayload = questionnaire_dto;
    let user_token = getAnonymousUserToken();

    if (!user_token){
        console.err('Erreur pas de connection vers l\'app');
        // Propose-t-on un reconnecte ??
    }
    let create_ou_update_evaluation_response = undefined;

    create_ou_update_evaluation_response = await net_update_or_create_evaluation(networkPayload, user_token);
    if ( 401 === create_ou_update_evaluation_response.status ){
        // tentative de refresh du token d'identification
        let refresh_token = getAnonymousRefreshToken();
        let refresh_response = await net_refresh_token(refresh_token);

        if ( refresh_response.ok ){
            setAnonymousRefreshToken(refresh_response.data.refresh_token);
            setAnonymousUserToken(refresh_response.data.token);
        }else{
            // si depuis un injecteur, on ne peut pas refresh un utilisateur anonyme
            // c'est trop étrange

            // voir pour faire un fail safe :
            // si pas de refresh, on utilise un unique id généré par le client
            // et on essaye d'identifier le client par id

            return {};
        }

        user_token = getIdentifiedUserToken();
        create_ou_update_evaluation_response = await net_update_or_create_evaluation(networkPayload, user_token);
    }
    if ( ! create_ou_update_evaluation_response.ok ){
        // problème réseau, quoi renvoyer ?
        return {};
    }

    // on met à jour la base de données
    if (db){
        // met à jour la base de données
    }
    return create_ou_update_evaluation_response.data;
}

const inject_create_ou_update_evaluation_2_pour_repondant = async (questionnaire_dto) => {
    const networkPayload = questionnaire_dto;
    let user_token = getIdentifiedUserToken();

    let create_ou_update_evaluation_response = undefined;

    create_ou_update_evaluation_response = await net_update_or_create_evaluation(networkPayload, user_token);
    if ( 401 === create_ou_update_evaluation_response.status ){
        // tentative de refresh du token d'identification
        let refresh_token = getIdentifiedRefreshToken();
        let refresh_response = await net_refresh_token(refresh_token);

        if ( refresh_response.ok ){
            setIdentifiedRefreshToken(refresh_response.data.refresh_token);
            setIdentifiedUserToken(refresh_response.data.token);
        }else{
            // il faut rediriger vers la page de login et
            // stocker l'intention pour la rappeler

            // pour le moment on renvoie vide
            return {};
        }
        user_token = getIdentifiedUserToken();
        create_ou_update_evaluation_response = await net_update_or_create_evaluation(networkPayload, user_token);
    }
    if ( ! create_ou_update_evaluation_response.ok ){
        // erreur
        return {};
    }

    return create_ou_update_evaluation_response.data;
}

const inject_create_ou_update_evaluation_2 = async (questionnaireDTO) => {

    // test d'envoi de la données
    // si problème de connexion, reconnect
    // retente envoi

    let userToken = getIdentifiedUserToken();
    if (userToken){
        return inject_create_ou_update_evaluation_2_pour_repondant(questionnaireDTO)
    }else{
        // on n'autorise pas les évaluations anonymes
        // return inject_create_ou_update_evaluation_2_pour_anonyme(questionnaireDTO);
    }
}

const inject_update_evaluation_verrou = async (evaluation_id, verrou_value) => {
    let user_token = getIdentifiedUserToken();

    let update_evaluation_verrou_response = undefined;

    update_evaluation_verrou_response = await net_update_evaluation_verrou(user_token, evaluation_id, verrou_value)
    if ( 401 === update_evaluation_verrou_response.status ){
        // tentative de refresh du token d'identification
        let refresh_token = getIdentifiedRefreshToken();
        let refresh_response = await net_refresh_token(refresh_token);

        if ( refresh_response.ok ){
            setIdentifiedRefreshToken(refresh_response.data.refresh_token);
            setIdentifiedUserToken(refresh_response.data.token);
        }else{
            // il faut rediriger vers la page de login et
            // stocker l'intention pour la rappeler

            // pour le moment on renvoie vide
            return {};
        }
        user_token = getIdentifiedUserToken();
        update_evaluation_verrou_response = await net_update_evaluation_verrou(user_token, evaluation_id, verrou_value)
    }
    if ( ! update_evaluation_verrou_response.ok ){
        // erreur
        return {};
    }
}

export {
    injecte_create_ou_update_evaluation,
    inject_update_evaluation,
    inject_create_ou_update_evaluation_2,
    inject_update_evaluation_verrou
}